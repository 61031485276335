import PropTypes from 'prop-types'
import React from 'react'

import style from './quote.module.css'


const Quote = ({ quote }) => (
  <blockquote className={style.quote}>
    “{quote}”
  </blockquote>
)
Quote.propTypes = {
  quote: PropTypes.string.isRequired,
}

export default Quote
