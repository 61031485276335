import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../components/breadcrumb'
import FragrancesList from '../components/fragrances-list'
import Heading from '../components/heading'
import Image from '../components/image'
import Layout from '../components/layout'
import Link from '../components/link'
import List from '../components/list'
import ListItem from '../components/list-item'
import Paragraph from '../components/paragraph'
import Quote from '../components/quote'
import Rating from '../components/rating'
import SEO from '../components/seo'


export const query = graphql`
  query BrandTemplateQuery(
    $brandId: String!,
    $genderId: PostGraphile_Gender!,
    $imageMain: String!,
  ) {
    ...SiteInfo
    imageMain: file(relativePath: { eq: $imageMain }) { ...ImageMain }
    results: postgres {
      brand: brandById(id: $brandId) {
        ...Brand
        country: countryByCountry {
          ...Country
        }
        fragrances: fragrancesByBrandList(condition: { gender: $genderId }) {
          ...Fragrance
        }
        noses: nosesList(genderId: $genderId) {
          ...Nose
        }
        notes: notesList(
          genderId: $genderId
          first: 5
        ) {
          ...Note
        }
      }
    }
  }
`

export default ({ data }) => {
  const { links, meta } = data.site.siteMetadata
  const { entity } = meta
  const { brand } = data.results
  const { fragrances, noses, notes } = brand
  const imageMain = data.imageMain.childImageSharp.fixed

  const page = brand.name
  const parent = 'Brands'
  const title = `${page} - ${entity.singular} ${parent}`
  const keywords = title.split(/\W+/)
  const trail = [
    {
      name: parent,
      slug: links.brands,
    },
    {
      name: page,
      slug: brand.slug,
    },
  ]
  const schema = {
    brand,
    breadcrumb: trail,
    description: brand.about,
    image: imageMain.src,
    keywords,
    name: title,
    slug: brand.slug,
  }

  return (
    <Layout>
      <SEO
        description={brand.about}
        keywords={keywords}
        schema={schema}
        title={title}
      />
      <Breadcrumb trail={trail} />

      <Heading>{page}</Heading>

      <Image
        alt={`${brand.name} Logo`}
        credit={brand.image}
        fixed={imageMain}
      />

      {brand.about && (
        <Paragraph>
          {brand.about}
        </Paragraph>
      )}

      {brand.slogan && (
        <Quote quote={brand.slogan} />
      )}

      <Paragraph>
        Made in {' '}
        <Link href={brand.country.slug}>
          {brand.country.name}
        </Link>
      </Paragraph>

      <Paragraph>
        <Link href={brand.link}>
          {brand.name} Official Website
        </Link>
      </Paragraph>

      <Heading level={2}>{brand.name} {entity.plural}</Heading>
      <Rating count={brand.ratings} rating={brand.rating} />
      <br/>
      <FragrancesList fragrances={fragrances} />

      {(noses.length > 0) && (
        <>
          <Heading level={2}>{brand.name} Noses</Heading>
          <List>
            {noses.map(nose => (
              <ListItem key={nose.id}>
                <Link href={nose.slug}>
                  {nose.name}
                </Link>
              </ListItem>
            ))}
          </List>
        </>
      )}

      <Heading level={2}>
        {brand.name} Notes
      </Heading>
      <List>
        {notes.map(note => (
          <ListItem key={note.id}>
            <Link href={note.slug}>
              {note.name}
            </Link>
          </ListItem>
        ))}
      </List>

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
